import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-toolbar" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = {
  class: "card card-bordered mb-5",
  id: "empRef"
}
const _hoisted_6 = { class: "mb-5 text-center" }
const _hoisted_7 = { class: "card-footer" }
const _hoisted_8 = { class: "container" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-sm-12 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_button_navigator = _resolveComponent("vc-button-navigator")!
  const _component_vc_information_modal = _resolveComponent("vc-information-modal")!
  const _component_vc_health_corporate_top = _resolveComponent("vc-health-corporate-top")!
  const _component_vc_health_employee = _resolveComponent("vc-health-employee")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_vc_button_save = _resolveComponent("vc-button-save")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "card-title" }, "Edit Group", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_vc_button_navigator, {
          text: "Back",
          value: "CustomerDetails",
          "class-name": "btn btn-sm btn-flex btn-info btn-active-info fw-bolder",
          "is-icon": "",
          params: { customer_id: _ctx.health.id }
        }, null, 8, ["params"])
      ])
    ]),
    _createVNode(_component_vc_information_modal),
    _createVNode(_component_el_form, {
      ref: "corporateForm",
      model: _ctx.health,
      "status-icon": "",
      rules: _ctx.rules,
      id: "healthForm",
      onSubmit: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.submitHealth(_ctx.corporateForm)), ["prevent"])),
      "scroll-to-error": ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_vc_health_corporate_top, {
            ref: "top",
            onReValidate: _ctx.revalidate,
            onTab: _ctx.checkform,
            isDisabledMain: "",
            isDisbaled: _ctx.health.hasDealInvoice,
            isExpectedStartDateDisabled: _ctx.health.hasDealInvoice && (!_ctx.health.is_renewal && !_ctx.health.is_llr)
          }, null, 8, ["onReValidate", "onTab", "isDisbaled", "isExpectedStartDateDisabled"]),
          _createElementVNode("div", _hoisted_5, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "card-header ribbon mb-5 mt-5" }, [
              _createElementVNode("div", { class: "card-title mb-5" }, [
                _createElementVNode("h6", { class: "card-subtitle text-muted" }, " Group Details ")
              ])
            ], -1)),
            _createVNode(_component_vc_health_employee, {
              ref: "empRef",
              isDisabled: _ctx.health.hasDealInvoice && !_ctx.health.is_renewal
            }, null, 8, ["isDisabled"]),
            (!_ctx.health.hasDealInvoice ||  _ctx.health.is_renewal)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _cache[3] || (_cache[3] = _createElementVNode("div", { class: "separator border-2 mb-5" }, null, -1)),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_el_button, {
                      type: "button",
                      class: "btn btn-lg btn-info w-10 h-auto",
                      onClick: _ctx.addNewCategory
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode(" Add Category ")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ], 64))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_vc_button_navigator, {
                  value: "CustomerDetails",
                  params: { customer_id: _ctx.health.id }
                }, null, 8, ["params"]),
                (!_ctx.health.hasDealInvoice ||  _ctx.health.is_renewal)
                  ? (_openBlock(), _createBlock(_component_vc_button_save, {
                      key: 0,
                      loading: _ctx.loading
                    }, null, 8, ["loading"]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}